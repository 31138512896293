import config from '@/config/config'
import { axios } from '@/api/index'
const externalUrl = config.baseUrl + '/exam/api/v1'
export default {
  verifyExternalSignature(payload) {
    return axios.post(`${externalUrl}/open/verify/signature`, payload)
  },
  getExternalPersonalReport(payload) {
    return axios.post(`${externalUrl}/open/get/personal/report`, payload)
  }
}
